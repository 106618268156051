import { collection, addDoc, Timestamp } from "firebase/firestore";
import { firestore } from "../firebase";

// Function to log user activity
export const logUserActivity = async (activity, qrID) => {
  try {
    const activitiesRef = collection(firestore, "user_activities");
    const timestamp = Timestamp.now();

    await addDoc(activitiesRef, {
      timestamp,
      activity,
      qrID: parseInt(qrID),
    });

    console.log("User activity logged successfully:", activity);
  } catch (error) {
    console.error("Error logging user activity:", error);
  }
};
