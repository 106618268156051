import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"; // Firestore functions
import { firestore } from "../../firebase"; // Firestore config

const FanZoneSchedule = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to format the date
  const formatDate = (primaryDate, secondaryDate) => {
    const formattedPrimaryDate = primaryDate
      .toDate()
      .toLocaleDateString("de-DE");
    if (secondaryDate) {
      const formattedSecondaryDate = secondaryDate
        .toDate()
        .toLocaleDateString("de-DE");
      return `${formattedPrimaryDate.split(".")[0]}/${
        formattedSecondaryDate.split(".")[0]
      }.${formattedPrimaryDate.split(".")[1]}.${
        formattedPrimaryDate.split(".")[2]
      }`;
    }
    return formattedPrimaryDate;
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "schedule"));
        const matchesData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            primaryDate: data.primaryDate,
            secondaryDate: data.secondaryDate,
            round: data.round,
          };
        });

        const now = new Date();
        const upcomingMatches = matchesData
          .filter((match) => match.primaryDate.toDate() >= now)
          .sort((a, b) => a.primaryDate.toDate() - b.primaryDate.toDate());

        setMatches(upcomingMatches);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching schedule:", error);
      }
    };

    fetchSchedule();
  }, []);

  return (
    <div className="mt-4">
      {loading ? (
        <div className="text-center">
          <p className="text-gray-900 text-lg">Učitavanje rasporeda...</p>
        </div>
      ) : matches.length > 0 ? (
        <div className="space-y-4">
          {matches.map((match, index) => (
            <div
              key={index}
              className="flex flex-col bg-white p-4 rounded-lg shadow-md"
            >
              {/* Round */}
              <p className="text-sm uppercase font-bold text-red-700 text-center mb-2">
                {match.round}
              </p>
              <hr className="border-red-700 mb-2" />{" "}
              {/* Horizontal line below the round */}
              <div className="flex w-full">
                {/* Home Team */}
                <div className="flex flex-col items-center w-1/4">
                  <img
                    src={match.homeTeam.logoUrl}
                    alt={match.homeTeam.title}
                    className="w-12 h-12 rounded-lg object-cover"
                  />
                  <p className="mt-2 text-base font-semibold text-gray-900 text-center">
                    {match.homeTeam.title}
                  </p>
                </div>

                {/* Venue, Date and Time */}
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                  <p className="text-xs text-gray-600">{match.venue}</p>
                  <p className="text-sm font-medium text-gray-800 mt-1">
                    {formatDate(match.primaryDate, match.secondaryDate)} -{" "}
                    {match.time}
                  </p>
                </div>

                {/* Guest Team */}
                <div className="flex flex-col items-center w-1/4">
                  <img
                    src={match.guestTeam.logoUrl}
                    alt={match.guestTeam.title}
                    className="w-12 h-12 rounded-lg object-cover"
                  />
                  <p className="mt-2 text-base font-semibold text-gray-900 text-center">
                    {match.guestTeam.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <p className="text-gray-900 text-lg">Nema dostupnih utakmica</p>
        </div>
      )}
    </div>
  );
};

export default FanZoneSchedule;
