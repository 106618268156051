import React, { useState, useEffect } from "react";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import Navbar from "../../components/shared/Navbar";
import Table from "../../components/shared/Table";
import QRCode from "qrcode.react";

const QrGenerator = () => {
  const [qrCodes, setQrCodes] = useState([]);
  const [selectedType, setSelectedType] = useState(""); // Define selectedType state
  const [numberOfCodes, setNumberOfCodes] = useState(1); // Define numberOfCodes state
  const [searchValue, setSearchValue] = useState("");

  const fetchQrCodes = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, "qr_codes"));
      const qrCodeData = querySnapshot.docs.map((doc) => doc.data());
      setQrCodes(qrCodeData);
    } catch (error) {
      console.error("Error fetching QR codes:", error);
    }
  };

  useEffect(() => {
    fetchQrCodes();
  }, []);

  const handleGenerateCodes = async () => {
    try {
      // Generate new QR codes based on selected type and number of codes
      for (let i = 0; i < numberOfCodes; i++) {
        const qrID = generateRandomNumber(10000, 99999);
        const isSeasonCard = selectedType === "season";
        const isActive = false; // Default is false
        const createdAt = new Date(); // Default is false

        // Add the new QR code to Firestore
        await addDoc(collection(firestore, "qr_codes"), {
          qrID,
          isSeasonCard,
          isActive,
          createdAt,
        });
      }

      // Refresh the QR codes list
      fetchQrCodes();
    } catch (error) {
      console.error("Error generating QR codes:", error);
    }
  };

  const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSearch = (data) => {
    return data.filter((item) => {
      return (
        item.qrID.toString().includes(searchValue) || // Search by qrID
        (item.isSeasonCard ? "Sezonske kartice" : "Članske kartice")
          .toLowerCase()
          .includes(searchValue.toLowerCase()) || // Search by Vrsta kartice
        (item.isActive ? "Aktivirana" : "Neaktivna")
          .toLowerCase()
          .includes(searchValue.toLowerCase()) // Search by Aktivirana
      );
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "QR ID",
        accessor: "qrID",
      },
      {
        Header: "Vrsta kartice",
        accessor: "isSeasonCard",
        Cell: ({ value }) => (value ? "Sezonske kartice" : "Članske kartice"),
      },
      {
        Header: "Aktivirana",
        accessor: "isActive",
        Cell: ({ value }) => (
          <span
            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
              value
                ? "bg-green-100 text-green-800"
                : "bg-orange-100 text-orange-800"
            }`}
          >
            {value ? "Aktivirana" : "Neaktivna"}
          </span>
        ),
      },
      {
        Header: "Preuzmi QR kod",
        accessor: "qrDownload",
        Cell: ({ row }) => (
          <div style={{ position: "relative" }}>
            <QRCode
              value={`https://app.mnkbubamara.ba/qr/${row.original.qrID}`}
              size={100}
              bgColor={"#FFFFFF"}
              fgColor={"#000000"}
              level={"L"}
            />
          </div>
        ),
      },
    ],
    []
  );

  const filteredData = handleSearch(qrCodes);

  return (
    <div className="flex h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 p-8 qr-generator-container">
        <h1 className="text-2xl mb-4">Generator QR kodova</h1>
        <div className="flex mb-4">
          <select
            className="bg-white border border-gray-300 rounded-md px-4 py-2 mr-4"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="">Odaberi vrstu</option>
            <option value="season">Sezonske kartice</option>
            <option value="membership">Članske kartice</option>
          </select>
          <input
            type="number"
            className="bg-white border border-gray-300 rounded-md px-4 py-2 w-20"
            value={numberOfCodes}
            onChange={(e) => setNumberOfCodes(parseInt(e.target.value))}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md ml-4"
            onClick={handleGenerateCodes}
          >
            Generiraj kodove
          </button>
          <input
            type="text"
            placeholder="Pretraži"
            className="bg-white border border-gray-300 rounded-md px-4 py-2 w-100 ml-auto"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto qr-generator-content">
          <Table data={filteredData} columns={columns} numOfRows={5} />
        </div>
      </div>
    </div>
  );
};

export default QrGenerator;
