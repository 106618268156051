import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faUsers,
  faSignOutAlt,
  faQrcode,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu

  const navigate = useNavigate();

  const handleLogout = () => {
    document.cookie =
      "authToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    navigate("/login");
  };

  return (
    <div className="bg-gray-800 text-white w-1/10 md:w-64 flex flex-col md:h-screen overflow-y-auto">
      <div className="flex items-center justify-between md:justify-center h-24 px-4 md:px-0">
        <img
          src="https://mnkbubamara.ba/wp-content/uploads/2023/06/logo-1.png"
          alt="Logo"
          className="w-16 h-16"
        />
        <button
          className="md:hidden mr-4"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <FontAwesomeIcon icon={faBars} style={{ marginRight: "0.5rem" }} />
        </button>
      </div>
      {(isMenuOpen || window.innerWidth >= 768) && (
        <nav className="py-4 flex-grow overflow-y-auto">
          <ul className="space-y-2">
            <li className="px-4 py-2 flex items-center hover:bg-gray-700">
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
              <Link
                to="/dashboard"
                className="uppercase font-bold text-sm md:block"
              >
                Kontrolna ploča
              </Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-gray-700">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              <Link
                to="/members"
                className="uppercase font-bold text-sm md:block cursor-pointer"
              >
                Članovi
              </Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-gray-700">
              <FontAwesomeIcon icon={faQrcode} className="mr-2" />
              <Link
                to="/qr-generator"
                className="uppercase font-bold text-sm md:block cursor-pointer"
              >
                QR Generator
              </Link>
            </li>
          </ul>
        </nav>
      )}
      <button
        onClick={handleLogout}
        className="bg-red-700 hover:bg-red-600 py-2 px-4 mt-auto"
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
        <span className="md:inline">Odjava</span>
      </button>
    </div>
  );
};

export default Navbar;
