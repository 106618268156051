import React, { useEffect, useState } from "react";

const FanZoneNews = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          "https://www.mnkbubamara.ba/wp-json/wp/v2/posts"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setNews(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news:", error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="mt-4">
      <hr className="border-red-700 mb-4" />
      {loading ? (
        <p>Učitavanje vijesti...</p>
      ) : news.length > 0 ? (
        <div className="space-y-4">
          {news.map((item) => {
            // Extract and truncate excerpt
            const excerpt = item.excerpt.rendered.replace(/<[^>]+>/g, "");
            const truncatedExcerpt =
              excerpt.length > 300 ? excerpt.slice(0, 300) + "..." : excerpt;

            return (
              <div
                key={item.id}
                className="bg-white rounded-lg shadow-md p-2 flex flex-col items-center"
              >
                {/* Thumbnail Image */}
                {item.jetpack_featured_media_url ? (
                  <img
                    src={item.jetpack_featured_media_url}
                    alt={item.title.rendered}
                    className="w-full h-32 object-cover rounded-lg mb-2"
                  />
                ) : (
                  <div className="w-full h-32 bg-gray-200 rounded-lg mb-2"></div>
                )}

                <h3 className="text-lg font-bold text-gray-900 mb-2">
                  {item.title.rendered}
                </h3>

                <p className="text-sm text-gray-600 text-justify mb-4 px-2">
                  {truncatedExcerpt}
                </p>

                {/* Button aligned to the left */}
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-700 text-white text-sm font-bold py-2 px-4 rounded self-start"
                >
                  Više
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No news available.</p>
      )}
    </div>
  );
};

export default FanZoneNews;
