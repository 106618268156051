import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/shared/Navbar";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import QRCode from "qrcode.react"; // Import QRCode component
import {
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaEnvelope,
  FaPhone,
  FaPencilAlt,
} from "react-icons/fa"; // Import Font Awesome icons for member data
import TabButton from "../../components/shared/TabButton";
import UserDonations from "../../components/user/UserDonations";
import UserMemberships from "../../components/user/UserMemberships";
import UserSeasonCards from "../../components/user/UserSeasonCards";
import UserActivity from "../../components/user/UserActivity";

const MemberDetail = () => {
  const { qrID } = useParams();
  const [member, setMember] = useState(null);
  const [activeTab, setActiveTab] = useState("activity");

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const q = query(
          collection(firestore, "users"),
          where("qrID", "==", qrID)
        ); // Filter by qrID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          console.log("Document data:", querySnapshot.docs[0].data()); // Log the retrieved data
          const data = querySnapshot.docs[0].data(); // Access the data of the first document
          setMember(data);
        } else {
          console.log("No such member found!");
        }
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    fetchMemberDetails();
  }, [qrID]); // Removed 'member' from the dependencies array

  // Render loading state while member data is being fetched
  if (!member) {
    return <div>Loading...</div>;
  }

  // Function to format the date of birth
  const formatDate = (dob) => {
    const date = new Date(dob.seconds * 1000); // Convert timestamp to milliseconds
    return date.toLocaleDateString("hr-HR"); // Format date as "dd.mm.yyyy"
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 p-8 overflow-y-auto">
        <div className="mb-4 flex items-center justify-between">
          <h1 className="text-2xl">{member.fullName}</h1>
          <button className="bg-red-700 text-white py-1 px-1 rounded-lg hover:bg-red-600 transition duration-300 h-8 w-8">
            <FaPencilAlt className="h-4 w-4 ml-1" />
          </button>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="flex flex-col md:w-2/3 md:pr-4">
            <div className="flex items-center border-gray-300 py-2">
              <FaMapMarkerAlt className="h-5 w-5 text-gray-400 mr-3" />
              <span>
                {member.address}, {member.city}, {member.country.label}
              </span>
            </div>
            <div className="flex items-center py-2">
              <FaCalendarAlt className="h-5 w-5 text-gray-400 mr-3" />
              <span>{formatDate(member.dob)}</span> {/* Format date of birth */}
            </div>
            <div className="flex items-center border-gray-300 py-2">
              <FaEnvelope className="h-5 w-5 text-gray-400 mr-3" />
              <span>{member.email}</span>
            </div>
            <div className="flex items-center border-gray-300 py-2">
              <FaPhone className="h-5 w-5 text-gray-400 mr-3" />
              <span>{member.phone}</span>
            </div>
          </div>
          <div className="flex flex-col items-center md:w-1/3 md:pl-4 mt-4 md:mt-0">
            <QRCode value={qrID.toString()} size={150} />
            <span className="text-sm text-gray-600 mt-2">{qrID}</span>
          </div>
        </div>
        <div className="flex mb-2 overflow-x-auto">
          <TabButton
            text="Aktivnost"
            active={activeTab === "activity"}
            onClick={() => setActiveTab("activity")}
          />
          <TabButton
            text="Članarine"
            active={activeTab === "membership"}
            onClick={() => setActiveTab("membership")}
          />
          <TabButton
            text="Sezonske ulaznice"
            active={activeTab === "seasonTickets"}
            onClick={() => setActiveTab("seasonTickets")}
          />
          <TabButton
            text="Donacije"
            active={activeTab === "donations"}
            onClick={() => setActiveTab("donations")}
          />
        </div>
        {activeTab === "activity" && <UserActivity qrID={qrID} />}
        {activeTab === "membership" && <UserMemberships />}
        {activeTab === "seasonTickets" && <UserSeasonCards />}
        {activeTab === "donations" && <UserDonations />}
      </div>
    </div>
  );
};

export default MemberDetail;
