import React, { useEffect, useState } from "react";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import Table from "../shared/Table";

const UserActivity = ({ qrID }) => {
  const [activities, setActivities] = useState([]);
  const columns = [
    { Header: "Vrijeme", accessor: "timestamp" },
    { Header: "Aktivnost", accessor: "activity" },
  ];

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const q = query(
          collection(firestore, "user_activities"),
          orderBy("timestamp", "desc") // Order by timestamp descending
        );
        const querySnapshot = await getDocs(q);
        const fetchedActivities = [];

        querySnapshot.forEach((doc) => {
          const { timestamp, activity, qrID: activityQrID } = doc.data();
          if (activityQrID === parseInt(qrID)) {
            fetchedActivities.push({
              timestamp: formatTimestamp(timestamp.toDate()), // Format timestamp
              activity,
              qrID: activityQrID,
            });
          }
        });

        setActivities(fetchedActivities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, [qrID]);

  // Function to format timestamp as "day.month.year hh:mm:ss"
  const formatTimestamp = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4 overflow-x-auto">
      <h2 className="text-lg font-bold mb-4">Aktivnosti</h2>
      <Table data={activities} columns={columns} numOfRows={5} />
    </div>
  );
};

export default UserActivity;
