import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase"; // Use the correct path to your firebase config
import FanZoneEventDetails from "./FanZoneEventDetails";

const FanZoneEvents = ({ userData, qrCodeData }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to manage selected event

  // Helper function to parse date string (dd.mm.yyyy) to a Date object
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(".").map(Number);
    return new Date(year, month - 1, day); // JavaScript months are 0-indexed
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(firestore, "events"));
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter events to include only those with today's or future dates
        const filteredEvents = eventsData.filter((event) => {
          const eventDate = parseDate(event.date);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set time to midnight to ignore time in comparison
          return eventDate >= today;
        });

        setEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
      setLoading(false);
    };

    fetchEvents();
  }, []);

  // Function to handle event click
  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  // Function to go back to the events list
  const handleBackToEvents = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="mt-4">
      <hr className="border-red-700 mb-4" />
      {loading ? (
        <div className="text-center">
          <p className="text-gray-900 text-lg">Učitavanje događaja...</p>
        </div>
      ) : selectedEvent ? (
        // Show EventDetails if an event is selected
        <FanZoneEventDetails
          event={selectedEvent}
          onBack={handleBackToEvents}
          userData={userData} // Pass userData if needed
          qrCodeData={qrCodeData} // Pass qrCodeData if needed
        />
      ) : events.length > 0 ? (
        // Show the list of events if no event is selected
        <div className="grid grid-cols-1 gap-6">
          {events.map((event) => (
            <div
              key={event.id}
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
              onClick={() => handleEventClick(event)} // Handle click on event
            >
              <img
                src={event.imageUrl}
                alt={event.title}
                className="w-full h-48 rounded-lg object-cover mb-4"
              />
              <div className="text-center">
                <h3 className="text-lg font-bold text-gray-900 mb-2">
                  {event.title}
                </h3>
                <p className="text-sm text-gray-600">
                  {event.date} - {event.time}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <p className="text-gray-900 text-lg">Nema nadolazećih događaja</p>
        </div>
      )}
    </div>
  );
};

export default FanZoneEvents;
