import React, { useEffect, useState, useMemo, useCallback } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebase";
import Navbar from "../../components/shared/Navbar";
import Table from "../../components/shared/Table";
import Loader from "../../components/shared/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Members = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage mobile menu open/close
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const [usersSnapshot, qrCodesSnapshot] = await Promise.all([
          getDocs(collection(firestore, "users")),
          getDocs(collection(firestore, "qr_codes")),
        ]);

        const qrCodesData = qrCodesSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc[data.qrID] = data.isSeasonCard;
          return acc;
        }, {});

        const userData = usersSnapshot.docs.map((doc) => {
          const data = doc.data();

          const isSeasonCard = qrCodesData[data.qrID] || false;

          return {
            id: doc.id,
            ...data,
            isSeasonCard,
          };
        });

        setUsers(userData);
        setFilteredUsers(userData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    filterUsers(activeTab, value);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    filterUsers(tab, searchValue);
    setIsDropdownOpen(false);
  };

  const filterUsers = (tab, searchValue) => {
    let filtered = users;
    if (tab === "season") {
      filtered = users.filter((user) => user.isSeasonCard);
    } else if (tab === "membership") {
      filtered = users.filter((user) => !user.isSeasonCard);
    }

    if (searchValue) {
      filtered = filtered.filter(
        (user) =>
          user.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.qrID.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
  };

  const viewMemberDetails = useCallback(
    (member) => {
      navigate(`/member/${member.qrID}`);
    },
    [navigate]
  );

  const confirmDeleteMember = useCallback((member) => {
    setSelectedMember(member);
  }, []);

  const deleteMember = async () => {
    try {
      if (selectedMember) {
        await deleteDoc(doc(firestore, "users", selectedMember.id));
        console.log("Member deleted successfully.");
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        const userData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userData);
        setSelectedMember(null);
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        id: "qrID",
        accessor: "qrID",
        Cell: ({ value }) => value,
      },
      {
        Header: "Ime i prezime",
        id: "fullName",
        accessor: "fullName",
      },
      {
        Header: "Email",
        id: "email",
        accessor: "email",
      },
      {
        Header: "Telefon",
        id: "phone",
        accessor: "phone",
      },
      {
        Header: "Akcije",
        id: "actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              onClick={() => viewMemberDetails(row.original)}
              className="bg-blue-500 text-white px-3 py-1 rounded-md mr-2"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
            <button
              onClick={() => confirmDeleteMember(row.original)}
              className="bg-red-500 text-white px-3 py-1 rounded-md"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        ),
      },
    ],
    [viewMemberDetails, confirmDeleteMember]
  );

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <div className="flex-1 p-8 overflow-x-auto">
        <h1 className="text-2xl mb-4">Članovi</h1>
        <div className="flex mb-4 items-center">
          <input
            type="text"
            placeholder="Pretraži po imenu ili ID-u"
            className="bg-white border border-gray-300 rounded-md px-4 py-2 mb-4 md:mb-0 w-full sm:w-96 md:mr-4"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="bg-gray-300 rounded-md px-4 py-2 text-left"
            >
              <FontAwesomeIcon icon={faFilter} /> Filteri
            </button>
            <div
              className={`${
                isDropdownOpen ? "block" : "hidden"
              } absolute mt-2 bg-white border border-gray-300 rounded-md shadow-md md:right-0 md:left-auto md:mt-0 md:bg-transparent md:shadow-none md:border-none`}
            >
              <button
                onClick={() => handleTabChange("all")}
                className={`px-4 py-2 rounded-md w-full text-left ${
                  activeTab === "all" ? "bg-blue-500 text-white" : "bg-gray-300"
                }`}
              >
                Svi
              </button>
              <button
                onClick={() => handleTabChange("membership")}
                className={`px-4 py-2 rounded-md w-full text-left ${
                  activeTab === "membership"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                }`}
              >
                Članske
              </button>
              <button
                onClick={() => handleTabChange("season")}
                className={`px-4 py-2 rounded-md w-full text-left ${
                  activeTab === "season"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                }`}
              >
                Sezonske
              </button>
              <button
                onClick={() => handleTabChange("other")}
                className={`px-4 py-2 rounded-md w-full text-left ${
                  activeTab === "other"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                }`}
              >
                Bubamarina krila
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <Loader />
          ) : (
            <Table data={filteredUsers} columns={columns} numOfRows={10} />
          )}
        </div>
      </div>
      {selectedMember && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-md shadow-md">
            <p className="mb-4">
              Jeste li sigurni da želite obrisati ovog člana?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setSelectedMember(null)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2"
              >
                Odustani
              </button>
              <button
                onClick={deleteMember}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Obriši
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
