// Table.jsx
import React from "react";
import { useTable, usePagination } from "react-table";

const Table = ({ data, columns, numOfRows }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: numOfRows },
    },
    usePagination
  );

  // Dynamically calculate the pagination buttons based on current page index
  const getPaginationButtons = () => {
    const currentPageIndex = pageIndex + 1;
    const totalPages = Math.ceil(data.length / numOfRows);
    const buttons = [];
    const numButtonsToShow = 5; // Adjust the number of pagination buttons shown

    // Determine the start and end page numbers
    let startPage = Math.max(
      1,
      currentPageIndex - Math.floor(numButtonsToShow / 2)
    );
    let endPage = Math.min(totalPages, startPage + numButtonsToShow - 1);

    // Adjust the start and end page numbers if necessary
    if (endPage - startPage + 1 < numButtonsToShow) {
      startPage = Math.max(1, endPage - numButtonsToShow + 1);
    }

    // Generate pagination buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => gotoPage(i - 1)} // Subtract 1 to match zero-based indexing
          className={`bg-gray-300 text-black px-3 py-1 rounded-md mx-1 ${
            pageIndex + 1 === i ? "bg-red-500 text-white" : ""
          }`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="overflow-x-auto">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200"
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  key={column.id}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200"
        >
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap"
                    key={cellIndex}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="bg-gray-300 text-black px-3 py-1 rounded-md"
          >
            {"<<"}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="bg-gray-300 text-black px-3 py-1 rounded-md"
          >
            {"<"}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          {getPaginationButtons()}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="bg-gray-300 text-black px-3 py-1 rounded-md"
          >
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="bg-gray-300 text-black px-3 py-1 rounded-md"
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
