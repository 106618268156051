import React from "react";

const TabButton = ({ text, active, onClick }) => {
  const activeClasses = active
    ? "bg-red-700 text-white font-bold"
    : "bg-red-500 hover:bg-red-700 text-white";

  return (
    <button
      className={`py-1 px-2 md:py-2 md:px-4 rounded uppercase text-xs md:text-sm mr-1 ${activeClasses}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default TabButton;
