import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import Loader from "../components/shared/Loader";
import { logUserActivity } from "../services/activityLogger"; // Import the logUserActivity function

const QrResolver = () => {
  const { qrID } = useParams();
  const [isActive, setIsActive] = useState(null); // Initialize isActive as null to handle loading state
  const [hasLoggedActivity, setHasLoggedActivity] = useState(false); // State to track if activity has been logged

  useEffect(() => {
    const fetchQrStatus = async () => {
      try {
        const q = query(
          collection(firestore, "qr_codes"),
          where("qrID", "==", parseInt(qrID))
        ); // Filter by qrID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const qrData = doc.data();
            const isActive = qrData.isActive;
            setIsActive(isActive);

            // Log user activity only if it hasn't been logged already
            if (!hasLoggedActivity) {
              logUserActivity("Korisnik skenirao QR kod", qrID);
              setHasLoggedActivity(true); // Update state to indicate activity has been logged
            }
          });
        } else {
          console.error("No documents found for qrID:", qrID);
          setIsActive(false); // Set isActive to false if no documents found

          // Log user activity only if it hasn't been logged already
          if (!hasLoggedActivity) {
            logUserActivity("Error: QR kod nije pronađen", qrID);
            setHasLoggedActivity(true); // Update state to indicate activity has been logged
          }
        }
      } catch (error) {
        console.error("Error fetching QR code status:", error);
        setIsActive(false); // Set isActive to false in case of error

        // Log user activity only if it hasn't been logged already
        if (!hasLoggedActivity) {
          logUserActivity("Error: Greška prilikom provjere QR koda", qrID);
          setHasLoggedActivity(true); // Update state to indicate activity has been logged
        }
      }
    };

    fetchQrStatus();
  }, [qrID, hasLoggedActivity]); // Include hasLoggedActivity in dependencies array

  if (isActive === null) {
    // Render your Loader component instead of the text
    return <Loader />;
  }

  // Redirect based on isActive status
  if (isActive) {
    return <Navigate to={`/qr/fanzone/${qrID}`} />;
  } else {
    return <Navigate to={`/qr/registration/${qrID}`} />;
  }
};

export default QrResolver;
