const FanZoneSquad = () => {
  // Sample data for players (replace this with actual data when available)
  const players = [
    {
      id: 1,
      name: "Borko Surudžić",
      position: "TRENER",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-768x960.jpg",
    },
    {
      id: 2,
      name: "Darko Milanović",
      position: "GOLMAN",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-1-768x960.jpg",
    },
    {
      id: 3,
      name: "Sejad Ibrišimović",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-2-768x960.jpg",
    },
    {
      id: 4,
      name: "Izudin Kličić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-3-768x960.jpg",
    },
    {
      id: 5,
      name: "Igor Osredkar",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-4-768x960.jpg",
    },
    {
      id: 6,
      name: "Luka Perić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-5-768x960.jpg",
    },
    {
      id: 7,
      name: "Elmin Prošić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-6-768x960.jpg",
    },
    {
      id: 8,
      name: "Edin Redžepović",
      position: "GOLMAN",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-11-768x960.jpg",
    },
    {
      id: 9,
      name: "Vladimir Džombić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-8-768x960.jpg",
    },
    {
      id: 10,
      name: "Kristijan Postružin",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/03/Next-Match-13-768x960.jpg",
    },
    {
      id: 11,
      name: "Edvin Bosnić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/10/Next-Match-768x960.jpg",
    },
    {
      id: 12,
      name: "Samir Gosto",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/10/Next-Match-1-768x960.jpg",
    },
    {
      id: 13,
      name: "Almedin Bundavica",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/10/Next-Match-2-768x960.jpg",
    },
    {
      id: 14,
      name: "Alen Omeragić",
      position: "IGRAČ",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/10/Next-Match-3-768x960.jpg",
    },
    {
      id: 15,
      name: "Edvin Čajić",
      position: "GOLMAN",
      image:
        "https://mnkbubamara.ba/wp-content/uploads/2024/10/Next-Match-4-768x960.jpg",
    },
  ];

  return (
    <div className="text-center">
      <p className="text-gray-900 text-lg">Tim 2024/2025</p>

      <div className="grid grid-cols-2 gap-4 mt-4">
        {players.map((player) => (
          <div key={player.id} className="flex flex-col items-center">
            <img
              src={player.image}
              alt={player.name}
              className="w-full h-auto rounded-md object-cover"
            />
            <p className="mt-2 text-gray-900 font-semibold">{player.name}</p>
            <p className="text-gray-600 text-sm">{player.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FanZoneSquad;
