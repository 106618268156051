// Import required libraries and components
import React, { useState, useEffect } from "react";
import {
  FaUserCircle,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import QRCode from "qrcode.react";
import FanZoneEvents from "../../components/fanzone/FanZoneEvents";
import FanZoneNews from "../../components/fanzone/FanZoneNews";
import FanZoneSchedule from "../../components/fanzone/FanZoneSchedule";
import FanZoneStandings from "../../components/fanzone/FanZoneStandings";
import FanZoneBubamaraTV from "../../components/fanzone/FanZoneBubamaraTV";
import FanZoneSquad from "../../components/fanzone/FanZoneSquad";

const tabs = [
  "Događaji",
  "Vijesti",
  "Raspored",
  "Tabela",
  "Tim",
  "Bubamara TV",
];

// Define arrays for partners and technical partners images
const proudPartners = [
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-20.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-26.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-21.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-22.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-23.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-24.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-25.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-18.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-17.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-16.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-14.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-19.png",
  // Add additional URLs for "Ponosni partneri" images here
];

const technicalPartners = [
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-27.png",
  "https://mnkbubamara.ba/wp-content/uploads/2024/11/Untitled-design-28.png",
  // Add additional URLs for "Tehnički partneri" images here
];

const FanZone = () => {
  const { qrID } = useParams();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [userData, setUserData] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userQuery = query(
          collection(firestore, "users"),
          where("qrID", "==", qrID)
        );
        const userSnapshot = await getDocs(userQuery);
        if (!userSnapshot.empty) {
          const user = userSnapshot.docs[0].data();
          setUserData(user);

          const qrQuery = query(
            collection(firestore, "qr_codes"),
            where("qrID", "==", Number(qrID))
          );
          const qrSnapshot = await getDocs(qrQuery);
          if (!qrSnapshot.empty) {
            setQrCodeData(qrSnapshot.docs[0].data());
          } else {
            console.error("No QR code data found for qrID:", qrID);
          }
        } else {
          console.error("No user data found for qrID:", qrID);
        }
      } catch (error) {
        console.error("Error fetching user or QR code data:", error);
      }
    };

    fetchUserData();
  }, [qrID]);

  const renderContent = () => {
    switch (activeTab) {
      case "Događaji":
        return <FanZoneEvents userData={userData} qrCodeData={qrCodeData} />;
      case "Vijesti":
        return <FanZoneNews />;
      case "Raspored":
        return <FanZoneSchedule />;
      case "Tabela":
        return <FanZoneStandings />;
      case "Tim":
        return <FanZoneSquad />;
      case "Bubamara TV":
        return <FanZoneBubamaraTV />;
      default:
        return null;
    }
  };

  const getUserStatus = () => {
    if (userData?.bubamaraWing) return "Bubamarino krilo";
    if (qrCodeData?.isSeasonCard) return "Sezonska kartica";
    return "Ponosni član";
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-red-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="space-y-2 w-full max-w-md">
        {/* User Info Header */}
        <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-lg">
          {/* User Info on the left */}
          <div className="flex items-center space-x-4">
            <div className="h-12 w-12 bg-gray-400 rounded-lg flex items-center justify-center">
              <FaUserCircle className="h-10 w-10 text-gray-200" />
            </div>
            <div>
              <h2 className="text-lg font-bold text-gray-900">
                {userData ? userData.fullName : "Učitavanje..."}
              </h2>
              <p className="text-sm text-gray-600">
                {userData ? getUserStatus() : "Učitavanje..."}
              </p>
            </div>
          </div>

          {/* QR Code on the right */}
          <div className="flex flex-col items-center">
            <QRCode value={qrID.toString()} size={48} />
            <span className="text-xs text-gray-600 mt-2">{qrID}</span>
          </div>
        </div>

        {/* Optional user contact info */}
        {userData && (
          <div className="mt-2 bg-white p-4 rounded-lg shadow-lg">
            {userData.phone && (
              <div className="flex items-center mb-2">
                <FaPhone className="text-gray-600 mr-2" />
                <span>{userData.phone}</span>
              </div>
            )}
            {userData.email && (
              <div className="flex items-center mb-2">
                <FaEnvelope className="text-gray-600 mr-2" />
                <span>{userData.email}</span>
              </div>
            )}
            {userData.address && (
              <div className="flex items-center mb-2">
                <FaMapMarkerAlt className="text-gray-600 mr-2" />
                <span>
                  {userData.address}, {userData.city}, {userData.country.label}
                </span>
              </div>
            )}
          </div>
        )}

        {/* Tab buttons */}
        <div className="mt-4">
          <div className="flex overflow-x-auto space-x-2 scrollbar-hide">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`whitespace-nowrap px-4 py-1 rounded-lg text-[11px] uppercase transition-all duration-300 ${
                  activeTab === tab
                    ? "bg-gray-700 text-white border-b-4 border-black"
                    : "bg-black text-white border-b-2 border-transparent hover:border-gray-600"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        {/* Tab content */}
        <div className="bg-white p-4 rounded-lg shadow-lg mt-4">
          {renderContent()}
        </div>

        {/* Partners Section */}
        {/* Partners Section */}
        <div className="bg-white p-4 rounded-lg shadow-lg mt-8">
          <h3 className="text-xl font-bold text-center text-red-800">
            PONOSNI PARTNERI
          </h3>
          <p className="text-center text-gray-600 mb-4">Sezona 2024/2025</p>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {proudPartners.map((image, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-2 flex items-center justify-center"
                style={{ width: "100%", aspectRatio: "1", overflow: "hidden" }}
              >
                <img
                  src={image}
                  alt={`Proud Partner ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>

          {/* Tehnički Partneri Section */}
          <h3 className="text-xl font-bold text-center text-red-800 mt-8">
            TEHNIČKI PARTNERI
          </h3>
          <p className="text-center text-gray-600 mb-4">Sezona 2024/2025</p>
          <div className="flex justify-center gap-4 flex-wrap">
            {technicalPartners.map((image, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-2 flex items-center justify-center"
                style={{ width: "100px", height: "100px", overflow: "hidden" }}
              >
                <img
                  src={image}
                  alt={`Technical Partner ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FanZone;
