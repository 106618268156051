import React, { useEffect, useState } from "react";
import Navbar from "../../components/shared/Navbar";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { FaUser, FaIdCard, FaBug } from "react-icons/fa"; // Import Font Awesome icons for cards
import Loader from "../../components/shared/Loader";

const Dashboard = () => {
  const [totalMembers, setTotalMembers] = useState(0);
  const [seasonCardUsers, setSeasonCardUsers] = useState(0);
  const [bubamarinaKrilaUsers, setBubamarinaKrilaUsers] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        // Fetch users and qr_codes data
        const [usersSnapshot, qrCodesSnapshot] = await Promise.all([
          getDocs(collection(firestore, "users")),
          getDocs(collection(firestore, "qr_codes")),
        ]);

        // Extract qr_codes data into a map for easier lookup
        const qrCodesData = qrCodesSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc[data.qrID] = data;
          return acc;
        }, {});

        // Calculate counts based on different criteria
        const totalMembersCount = usersSnapshot.docs.length;
        const seasonCardUsersCount = usersSnapshot.docs.filter((doc) => {
          const userData = doc.data();
          const qrData = qrCodesData[userData.qrID];
          return qrData && qrData.isSeasonCard;
        }).length;
        const bubamarinaKrilaUsersCount = usersSnapshot.docs.filter((doc) => {
          const userData = doc.data();
          const qrData = qrCodesData[userData.qrID];
          return qrData && qrData.bubamarinaKrila;
        }).length;

        // Set state with the counts
        setTotalMembers(totalMembersCount);
        setSeasonCardUsers(seasonCardUsersCount);
        setBubamarinaKrilaUsers(bubamarinaKrilaUsersCount);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user stats:", error);
      }
    };

    fetchUserStats();
  }, []);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 p-8">
        <h1 className="text-2xl mb-4">Dobrodošli!</h1>
        {/* Loading indicator */}
        {loading ? (
          <Loader />
        ) : (
          // Cards section
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Total Members */}
            <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
              <FaUser className="text-4xl text-blue-500 mr-4" />
              <div>
                <p className="text-lg font-semibold">Broj članova</p>
                <p className="text-gray-500">{totalMembers}</p>
              </div>
            </div>
            {/* Season Card Users */}
            <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
              <FaIdCard className="text-4xl text-green-500 mr-4" />
              <div>
                <p className="text-lg font-semibold">Sezonske kartice</p>
                <p className="text-gray-500">{seasonCardUsers}</p>
              </div>
            </div>
            {/* Bubamarina Krila Users */}
            <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
              <FaBug className="text-4xl text-red-500 mr-4" />
              <div>
                <p className="text-lg font-semibold">Bubamarina krila</p>
                <p className="text-gray-500">{bubamarinaKrilaUsers}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
