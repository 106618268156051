import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"; // Firestore functions
import { firestore } from "../../firebase"; // Firestore config

const FanZoneStandings = () => {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "standings"));
        let standingsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort by position
        standingsData = standingsData.sort((a, b) => a.position - b.position);

        setStandings(standingsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching standings:", error);
      }
    };

    fetchStandings();
  }, []);

  return (
    <div className="mt-4">
      <hr className="border-red-700 mb-4" />
      {loading ? (
        <div className="text-center">
          <p className="text-gray-900 text-lg">Učitavanje tabela...</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead className="bg-red-700 text-white">
              <tr>
                <th className="px-4 py-1 text-left text-[10px]">#</th>
                <th className="px-4 py-1 text-left text-[10px]"></th>
                <th className="px-4 py-1 text-left text-[10px]">TIM</th>
                <th className="px-4 py-1 text-left text-[10px]">O</th>
                <th className="px-4 py-1 text-left text-[10px]">P</th>
                <th className="px-4 py-1 text-left text-[10px]">N</th>
                <th className="px-4 py-1 text-left text-[10px]">I</th>
                <th className="px-4 py-1 text-left text-[10px]">+/-</th>
                <th className="px-4 py-1 text-left text-[10px]">B</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team) => (
                <tr key={team.position} className="border-b border-gray-300">
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.position}
                  </td>
                  <td className="py-1 text-center">
                    <img
                      src={team.teamLogo}
                      alt={team.teamName}
                      className="w-8 h-8 object-cover mx-auto"
                    />
                  </td>
                  <td className="px-4 py-1 text-[10px]">{team.teamName}</td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.matches}
                  </td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.wins}
                  </td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.draws}
                  </td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.losses}
                  </td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.gd}
                  </td>
                  <td className="px-4 py-1 text-center text-[10px]">
                    {team.points}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FanZoneStandings;
