// UserDonations.js
import React from "react";

const UserDonations = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <p>TODO Donations</p>
    </div>
  );
};

export default UserDonations;
