import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

const FanZoneBubamaraTV = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "bubamaraTV")
        );
        const videosData = querySnapshot.docs.map((doc) => doc.data());
        setVideos(videosData);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="text-center">
      <p className="text-gray-900 text-lg">Bubamara TV</p>
      <div className="mt-4 space-y-4">
        {videos.map((video, index) => (
          <div key={index} className="w-full h-64">
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${video.id}`} // Construct the embed URL
              title={`Bubamara TV Video ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FanZoneBubamaraTV;
