// UserMemberships.js
import React from "react";

const UserMemberships = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <p>TODO Memberships</p>
    </div>
  );
};

export default UserMemberships;
